// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { findEl, findEls } from 'src/helpers/finders';

class FieldRulesController extends Controller {
  static targets = [
    'triggerFieldIcon',
    'triggerFieldArea',
    'triggerFieldInstructions',
    'affectedFieldIcon',
    'affectedFieldInstructions',
    'affectedFieldTemplate',
    'clearAll',
  ];

  static values = { mode: String, newAffectedFields: Array<string> };

  triggerFieldIconTarget!: HTMLElement;
  triggerFieldAreaTarget!: HTMLElement;
  triggerFieldInstructionsTarget!: HTMLElement;
  affectedFieldIconTarget!: HTMLElement;
  affectedFieldInstructionsTarget!: HTMLElement;
  affectedFieldTemplateTarget!: HTMLTemplateElement;
  clearAllTarget!: HTMLElement;

  modeValue: string;
  newAffectedFieldsValue: string[];
  hasTriggerFieldInstructionsTarget!: boolean;
  hasAffectedFieldInstructionsTarget!: boolean;

  connect(): void {
    this.newAffectedFieldsValue = [];
  }

  setTriggerMode(): void {
    this.modeValue = 'triggerMode';
    this.triggerFieldIconTarget.classList.add('mode-icon__selected');
    this.affectedFieldIconTarget.classList.remove('mode-icon__selected');
  }

  setAffectedMode(): void {
    this.modeValue = 'affectedMode';
    this.affectedFieldIconTarget.classList.add('mode-icon__selected');
    this.triggerFieldIconTarget.classList.remove('mode-icon__selected');
  }

  clearMode(): void {
    this.modeValue = '';
    this.triggerFieldIconTarget.classList.remove('mode-icon__selected');
    this.affectedFieldIconTarget.classList.remove('mode-icon__selected');
  }

  toggle(event: ActionEvent & DOMEvent): void {
    const {
      fieldNumber,
      fieldLabel,
      fieldType,
      choices,
    } = event.currentTarget.dataset;
    if (this.modeValue === 'triggerMode' && this.isTriggerFieldType(fieldType)) {
      this.replaceTriggerNumber(fieldNumber, fieldLabel);
      this.replaceTriggerOptions(choices);
    } else if (this.modeValue === 'affectedMode') {
      this.updateAffectedFieldsList(fieldNumber, fieldLabel);
    }
  }

  isTriggerFieldType(fieldType: string): boolean {
    return fieldType === 'Checkbox' || fieldType === 'Dropdown';
  }

  replaceTriggerNumber(fieldNumber: string, fieldLabel: string): void {
    this.triggerFieldInstructionsTarget.classList.add('rule-area__hidden');
    this.triggerFieldAreaTarget.classList.remove('rule-area__hidden');

    const labelElement = findEl(this.triggerFieldAreaTarget, 'span', '.wb-pill');
    labelElement.firstChild.textContent = fieldLabel;

    this.unhighlightTrigger();

    const trigger = document.querySelector(`[data-field-number='${fieldNumber}']`);
    trigger.classList.add('trigger-field-highlight');

    const hiddenField =
      findEl(document, 'input', "[name='trigger_field_number']");

    hiddenField.value = fieldNumber;
  }

  replaceTriggerOptions(choices: string): void {
    const options = choices ? JSON.parse(choices) : ['checked', 'unchecked'];
    const selectElement = findEl(this.triggerFieldAreaTarget, 'select');
    const [optionElement] = selectElement.children;

    const newOptions: [HTMLInputElement] =
      options.reduce(
        (accumulator: [HTMLInputElement], choice) => {
          const newOption = optionElement.cloneNode(true) as HTMLInputElement;
          newOption.value = choice;
          newOption.textContent = choice;
          newOption.removeAttribute('selected');
          accumulator.push(newOption);
          return accumulator;
        },
        [],
      );

    selectElement.replaceChildren(...newOptions);
  }

  updateAffectedFieldsList(fieldNumber: string, fieldLabel: string): void {
    this.affectedFieldInstructionsTarget.classList.add('rule-area__hidden');
    this.toggleClearAll('show');

    const hiddenField =
      findEl(document, 'input', "[name='affected_field_numbers']");
    if (!hiddenField.value.trim().split(' ').includes(fieldNumber)) {
      const template = this.affectedFieldTemplateTarget.content.firstElementChild;
      const newAffectedFieldElement = template.cloneNode(true) as HTMLElement;
      newAffectedFieldElement.firstChild.textContent = fieldLabel;
      this.clearAllTarget.before(newAffectedFieldElement);
      this.highlightAffectedField(fieldNumber);

      hiddenField.value += ` ${fieldNumber}`;
    }
  }

  removeTrigger(): void {
    this.unhighlightTrigger();

    this.triggerFieldInstructionsTarget.classList
      .remove('rule-area__hidden');
    this.triggerFieldAreaTarget.classList.add('rule-area__hidden');

    const hiddenField =
      findEl(document, 'input', "[name='trigger_field_number']");
    hiddenField.value = null;
  }

  removeAffectedFields(): void {
    document.querySelectorAll('.affected-field-highlight').forEach((element) => {
      element.classList.remove('affected-field-highlight');
    });

    const pills = findEls(document, 'span', '.wb-pill--brand-alt');
    pills.forEach((pill: HTMLElement) => { pill.remove(); });

    findEl(document, 'input', "[name='affected_field_numbers']").value = '';

    this.affectedFieldInstructionsTarget.classList.remove('rule-area__hidden');

    this.toggleClearAll('hide');
  }

  removeAffectedField(event: DOMEvent): void {
    const affectedFieldPill = event.currentTarget.parentElement;
    const label = affectedFieldPill.firstChild.textContent.trim();
    const highlightedField: HTMLElement =
      document.querySelector(`[data-field-label='${label}']`);

    highlightedField.classList.remove('affected-field-highlight');

    affectedFieldPill.remove();

    const hiddenField =
      this.removeAffectedFieldValue(highlightedField.dataset.fieldNumber);

    if (hiddenField.value === '') {
      this.affectedFieldInstructionsTarget.classList.remove('rule-area__hidden');
      this.toggleClearAll('hide');
    }
  }

  removeAffectedFieldValue(fieldNumber: string): HTMLInputElement {
    const hiddenField =
      findEl(document, 'input', "[name='affected_field_numbers']");

    hiddenField.value =
      hiddenField.value.replace(fieldNumber, '').replace(/\s+/gu, ' ').trim();

    return hiddenField;
  }

  highlightFields(event: DOMEvent): void {
    this.unhighlightFields();

    const { triggerFieldNumber, affectedFieldNumbers } =
      event.currentTarget.dataset;
    const trigger =
      document.querySelector(`[data-field-number='${triggerFieldNumber}']`);

    if (trigger) {
      trigger.classList.add('trigger-field-highlight');
    }

    JSON.parse(affectedFieldNumbers).forEach((number: string) => {
      this.highlightAffectedField(number);
    });
  }

  unhighlightFields(): void {
    this.unhighlightTrigger();

    document.querySelectorAll('.affected-field-highlight').forEach((element) => {
      element.classList.remove('affected-field-highlight');
    });
  }

  highlightAffectedField(fieldNumber: string): void {
    const field = document.querySelector(`[data-field-number='${fieldNumber}']`);
    if (field) {
      field.classList.add('affected-field-highlight');
    }
  }

  unhighlightTrigger(): void {
    const trigger = document.querySelector('.trigger-field-highlight');
    if (trigger) {
      trigger.classList.remove('trigger-field-highlight');
    }
  }

  toggleClearAll(action: string): void {
    const clearAll = findEl(document, 'div', '.wb-link--muted');

    if (action === 'hide') {
      clearAll.classList.add('rule-area__hidden');
    } else if (action === 'show') {
      clearAll.classList.remove('rule-area__hidden');
    }
  }

  validateFieldsSelected(event: DOMEvent): void {
    const saveButton = findEl(document, 'input', '.wb-button--primary');
    let errorMessage = '';

    if (this.instructionsVisible(this.triggerFieldInstructionsTarget)) {
      errorMessage = 'Please select a trigger field.';
    } else if (this.instructionsVisible(this.affectedFieldInstructionsTarget)) {
      errorMessage = 'Please select at least one show field.';
    }

    saveButton.setCustomValidity(errorMessage);
    saveButton.reportValidity();

    if (errorMessage) { event.preventDefault(); }
  }

  instructionsVisible(target: HTMLElement): boolean {
    return !target.classList.contains('rule-area__hidden');
  }
}

export default FieldRulesController;
