// @ts-strict-ignore
import { DirectUpload } from 'activestorage';

import FileUploaderController from 'controllers/file_uploader_controller';
import $template from 'src/helpers/dollar_template';
import notify from 'src/helpers/notify';
import valToString from 'src/helpers/val_to_string';

type UploadResult = {
  filename: string;
  signedId: string;
};

class FormFillerAttachmentUploaderController extends FileUploaderController {
  validExtensions: string[];

  connect(): void {
    super.connect();

    this.validExtensions = [
      '.jpg', '.jpeg', '.png', '.bmp', '.tiff', '.tif', '.pdf', '.doc', '.docx',
      '.txt', '.xls', '.xlsm', '.xlsx', '.csv', '.msg',
    ];
  }

  blobRowInputName(): string {
    return 'form_request[blob_ids][]';
  }

  maxFiles(): number {
    return 30;
  }

  addFiles($fileSelect: JQuery, files: File[]): void {
    this.$errorMsg.hide();

    $fileSelect.find('.uploader-file-selector').hide();
    this.fileAddStarted();

    const promises: Promise<UploadResult>[] = [];

    files.forEach((file: File) => {
      promises.push(getDirectUploadData(file));
    });

    Promise.allSettled(promises).then((results) => {
      const failedUploads: UploadResult[] = [];
      const successfulUploads: UploadResult[] = [];

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          successfulUploads.push(result.value);
        } else {
          failedUploads.push(result.reason);
        }
      });
      if (failedUploads.length > 0) {
        const $input = $fileSelect.find('.uploader-file-selector input');

        $input.val('');

        const failedFilenames = failedUploads.map((upload) => {
          return upload.filename;
        }).join(', ');
        const message = 'We were unable to upload your files. ' +
          'Please make sure the following files are valid and try again: ' +
          `<b>${failedFilenames}</b>`;

        this.$errorMsg.html(message).show();
      } else {
        const $blobRow = $template(
          'blob-row-template',
          { inputName: this.blobRowInputName(), successfulUploads },
        );

        $blobRow.find('.uploader-file-remove').on('click', this.removeFile);
        $fileSelect.append($blobRow);
      }
      this.fileAddEnded();
      this.updateUIElements();
    });
  }

  fileAddStarted(): void {
    deactivateSaveAndSubmitButtons();
  }

  fileAddEnded(): void {
    activateSaveAndSubmitButtons();
  }
}

// private

function getDirectUploadData(file: File): Promise<UploadResult> {
  const url = '/rails/active_storage/direct_uploads';
  const directUpload = new DirectUpload(file, url);

  return new Promise((resolve, reject) => {
    directUpload.create((error, blob) => {
      const result: UploadResult = {
        filename: file.name,
        signedId: '',
      };

      if (error || blob.byte_size === 0) {
        const context = {
          error,
          filename: file.name,
          signedId: blob && blob.signed_id,
          size: file.size,
          type: file.type,
        };

        notify('direct upload failed', context);
        reject(result);
      } else {
        result.signedId = blob.signed_id;
        resolve(result);
      }
    });
  });
}

function activateSaveAndSubmitButtons(): void {
  $('input[type=submit]').prop('disabled', false).each((__, button) => {
    const defaultText = $(button).data('text');

    $(button).val(defaultText);
  });
}

function deactivateSaveAndSubmitButtons(): void {
  $('input[type=submit]').prop('disabled', true).each((__, button) => {
    const defaultText = valToString($(button).val());

    $(button).data('text', defaultText).val('Uploading...');
  });
}

export default FormFillerAttachmentUploaderController;
