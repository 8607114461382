// @ts-strict-ignore
import { findEl } from 'src/helpers/finders';
import FormFillerAttachmentUploaderController
  from './form_filler_attachment_uploader_controller';

class AttachmentFieldController extends FormFillerAttachmentUploaderController {
  static values = { fieldId: Number };

  fieldIdValue: number;

  private get doneButton(): HTMLButtonElement {
    return findEl(this.element, 'button', '.done-button');
  }

  private get modalOverlay(): HTMLElement {
    return findEl(this.element, 'div', '.dialog--overlay');
  }

  blobRowInputName(): string {
    return `form_request[attachment_fields][${this.fieldIdValue}][blob_ids][]`;
  }

  fileAddStarted(): void {
    super.fileAddStarted();

    this.doneButton.disabled = true;
    this.doneButton.innerText = 'Uploading...';

    delete this.modalOverlay.dataset.a11yDialogHide;
  }

  fileAddEnded(): void {
    super.fileAddEnded();

    this.doneButton.disabled = false;
    this.doneButton.innerText = 'Done';

    this.modalOverlay.dataset.a11yDialogHide = 'true';
  }
}

export default AttachmentFieldController;
