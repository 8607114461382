type FieldConfig = {
  editable: boolean;
  fieldRuleShowable: boolean;
  labelable: boolean;
  prefillable: boolean;
  requireable: boolean;
  resizable: boolean;
  stepSelectable: boolean;
};

const FieldConfigs: { [key: string]: FieldConfig } = {
  Attachment: {
    editable: false,
    fieldRuleShowable: true,
    labelable: false,
    prefillable: false,
    requireable: false,
    resizable: false,
    stepSelectable: true,
  },
  Checkbox: {
    editable: true,
    fieldRuleShowable: true,
    labelable: true,
    prefillable: false,
    requireable: false,
    resizable: false,
    stepSelectable: true,
  },
  Date: {
    editable: true,
    fieldRuleShowable: true,
    labelable: true,
    prefillable: true,
    requireable: true,
    resizable: false,
    stepSelectable: true,
  },
  Dropdown: {
    editable: true,
    fieldRuleShowable: true,
    labelable: true,
    prefillable: true,
    requireable: true,
    resizable: false,
    stepSelectable: true,
  },
  Formula: {
    editable: false,
    fieldRuleShowable: false,
    labelable: true,
    prefillable: false,
    requireable: false,
    resizable: false,
    stepSelectable: true,
  },
  Input: {
    editable: true,
    fieldRuleShowable: true,
    labelable: true,
    prefillable: true,
    requireable: true,
    resizable: true,
    stepSelectable: true,
  },
  Prefill: {
    editable: true,
    fieldRuleShowable: false,
    labelable: false,
    prefillable: false,
    requireable: true,
    resizable: false,
    stepSelectable: true,
  },
  ReferenceNumber: {
    editable: false,
    fieldRuleShowable: false,
    labelable: true,
    prefillable: false,
    requireable: false,
    resizable: false,
    stepSelectable: false,
  },
  Signature: {
    editable: false,
    fieldRuleShowable: true,
    labelable: true,
    prefillable: false,
    requireable: true,
    resizable: false,
    stepSelectable: true,
  },
  StaticText: {
    editable: false,
    fieldRuleShowable: true,
    labelable: false,
    prefillable: false,
    requireable: false,
    resizable: false,
    stepSelectable: true,
  },
  Time: {
    editable: true,
    fieldRuleShowable: true,
    labelable: true,
    prefillable: true,
    requireable: true,
    resizable: false,
    stepSelectable: true,
  },
};

export default FieldConfigs;
export type { FieldConfig };
