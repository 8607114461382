import React from 'react';

import PaginatorComponent from 'src/common/components/paginator';
import ResponsesTable from 'src/responses/components/table';

type Props = {
  campaign: Campaign;
  headers: Responses.TableHeader[];
  pagination: Pagination;
  queryParams: ResponsesQueryParams;
  responses: FormResponse[];
  steps: Step[];
};

export default function ResponsesTableFrame(props: Props): React.JSX.Element {
  const {
    campaign,
    headers,
    pagination,
    queryParams,
    responses,
    steps,
  } = props;

  return (
    <div className='responses-data'>
      <ResponsesTable
        campaign={campaign}
        headers={headers}
        queryParams={queryParams}
        responses={responses}
        steps={steps}
      />
      <PaginatorComponent
        ariaLabel='Responses Pagination'
        pagination={pagination}
        paginatorClassNames='responses-pagination'
      />
    </div>
  );
}

export type { Props };
