// @ts-strict-ignore
import isNil from 'lodash/isNil';
import React, { ReactNode } from 'react';

import { assert } from 'src/helpers/assertion';
import { pluralizeWithCount } from 'src/helpers/pluralize';
import { UpdateMeta } from 'src/steps/action_creators';

const BASE_CONNETOR_HEIGHT = 50; // this corresponds the height of .stick * 2
const BASE_NODE_HEIGHT = 40;

type Props = {
  allFormsCount: number | null;
  hasQuestion: boolean;
  id: number;
  label: string;
  notificationsCount: number;
  recipientsCount: number | null;
  selectedStepId: number | null;
  seq: number;
  emailColumns: string[] | null;
  sourceSeq: number | null;
  stepFormsCount: number | null;
  stepsCount: number;
  type: StepType;
  updateMeta: UpdateMeta;
  userPermissions: UserPermission[];
};

class StepNode extends React.Component<Props, never> {
  private stepPopoverRef = React.createRef<HTMLButtonElement>();

  componentDidMount(): void {
    const stepPopover = this.stepPopoverRef.current;

    if (stepPopover) { $(stepPopover).popover(); }
  }

  render(): ReactNode {
    const {
      id,
      seq,
      updateMeta,
      stepFormsCount,
      allFormsCount,
      stepsCount,
      selectedStepId: selectedId,
    } = this.props;
    const nodeSize = _nodeSize(stepFormsCount, allFormsCount);
    const inactiveCircle = selectedId && selectedId !== id ? 'inactive' : '';
    const inactiveStick = selectedId ? 'inactive' : '';
    const popoverDataAttributes = {
      'data-content': this.stepPopoverText(),
      'data-html': 'true',
      'data-placement': 'bottom',
      'data-toggle': 'popover',
      'data-trigger': 'hover',
    };

    return (
      <div className='row-step'>
        <div className='step-node'>
          <div
            className={
              'stick stick-top ' +
              `${inactiveStick} ${seq === 1 ? 'transparent' : ''}`
            }
          >
          </div>
          <button
            ref={this.stepPopoverRef}
            className={`circle ${inactiveCircle} link-text link-no-underline`}
            style={_circleStyle(nodeSize)}
            type='button'
            onClick={(): void => { updateMeta({ selectedStepId: id }); }}
            {...popoverDataAttributes}
          >
            {stepFormsCount}
          </button>
          <div
            className={
              'stick stick-bottom ' +
              `${inactiveStick} ${seq === stepsCount ? 'transparent' : ''}`
            }
          >
          </div>
        </div>
        <button
          className={`step-info ${inactiveCircle} link-text link-no-underline`}
          style={_labelStyle(nodeSize)}
          type='button'
          onClick={(): void => { updateMeta({ selectedStepId: id }); }}
        >
          { this.stepLabel() }
        </button>
      </div>
    );
  }

  stepLabel(): React.JSX.Element {
    const {
      seq,
      label,
      userPermissions,
    } = this.props;

    return (
      <div className='step-label'>
        <h5 className='wb-heading-5 step-label__heading'>
          <small className='step-label__step'>Step {seq}</small>
          <br />
          <span className='text-truncated'>{label}</span>
        </h5>
        { userPermissions.includes('view_step_approver') && this.summary() }
      </div>
    );
  }

  groupEmailColumn(): string {
    const { emailColumns } = this.props;

    return `Sent to: ${emailColumns.join(', ')}`;
  }

  recipientStepSummary(): string {
    const { recipientsCount } = this.props;

    return pluralizeWithCount(assert(recipientsCount), 'approver');
  }

  priorApproverStepSummary(): string {
    const { sourceSeq } = this.props;

    return `Approved by person at Step ${sourceSeq}`;
  }

  stepSummaryText(): string[] {
    const { type, notificationsCount, hasQuestion } = this.props;

    const stepSummaryText: string[] = [];

    if (type === 'PromptStep') {
      stepSummaryText.push('Approver entered by prior step');
    } else if (type === 'RecipientStep') {
      stepSummaryText.push(this.recipientStepSummary());
    } else if (type === 'PriorApproverStep') {
      stepSummaryText.push(this.priorApproverStepSummary());
    } else if (type === 'SequentialGroupStep' || type === 'FirstComeGroupStep') {
      stepSummaryText.push(this.groupEmailColumn());
    }

    if (notificationsCount > 0) {
      stepSummaryText.push(`${notificationsCount} cc`);
    }

    if (hasQuestion) { stepSummaryText.push('Has question'); }

    return stepSummaryText;
  }

  summary(): React.JSX.Element {
    const stepSummaryText = this.stepSummaryText();

    if (stepSummaryText.length === 0) { return null; }

    return (
      <p className='wb-text wb-text--muted wb-text--small step-label__summary'>
        {stepSummaryText.join(' • ')}
      </p>
    );
  }

  stepPopoverText(): string {
    const {
      seq,
      stepFormsCount,
    } = this.props;

    const stepTitle = `<p><strong>Step ${seq}</strong></p>`;
    const pending = `<span>${stepFormsCount} pending forms</span>`;

    let popoverText = stepTitle;

    if (!isNil(stepFormsCount)) {
      popoverText += pending;
    }

    return popoverText;
  }
}

// private

function _nodeSize(stepFormsCount: number, allFormsCount: number): number {
  if (isNil(allFormsCount) || allFormsCount === 0) {
    return BASE_NODE_HEIGHT * 1.5;
  }

  const formCountHeight = (stepFormsCount / allFormsCount) * BASE_NODE_HEIGHT;

  return formCountHeight + BASE_NODE_HEIGHT;
}

function _circleStyle(nodeSize: number): {
  borderRadius: string;
  height: string;
  lineHeight: string;
  width: string;
} {
  return {
    borderRadius: `${nodeSize * 2}px`,
    height: `${nodeSize}px`,
    lineHeight: `${nodeSize}px`,
    width: `${nodeSize}px`,
  };
}

function _labelStyle(nodeSize: number): { height: string } {
  return { height: `${nodeSize + BASE_CONNETOR_HEIGHT}px` };
}

export default StepNode;
export type { Props };
