// @ts-strict-ignore
import Field from 'src/doc_editor/field';
import $template from 'src/helpers/dollar_template';

class Attachment extends Field {
  $preview(): JQuery {
    const $previewElement = $template(
      'attachment-field-preview-template',
      { required: this.model.get('required') },
    );

    const previewElement: HTMLElement = $previewElement.get(0);
    previewElement.style.height = this.$element.height().toString();
    previewElement.style.left = this.$element.position().left.toString();
    previewElement.style.top = this.$element.position().top.toString();
    previewElement.style.width = this.$element.width().toString();

    return $previewElement;
  }
}

export default Attachment;
