import FieldModel from 'src/doc_editor/fields/models/field';

const Attachment = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    required: false,
  },
});

export default Attachment;
