import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

import DateRangeFilterModal from 'src/responses/components/date_range_filter_modal';

class DateRangeController extends Controller {
  static values = {
    filterKey: String,
    filterLabel: String,
  };

  filterKeyValue!: string;
  filterLabelValue!: string;

  connect(): void {
    ReactDOM.render(this.component(), this.element);
  }

  component(): React.JSX.Element {
    return (
      <React.StrictMode>
        <DateRangeFilterModal
          filterKey={this.filterKeyValue}
          filterLabel={this.filterLabelValue}
        />
      </React.StrictMode>
    );
  }

  disconnect(): void {
    ReactDOM.unmountComponentAtNode(this.element);
  }
}

export default DateRangeController;
