import { Controller } from '@hotwired/stimulus';

const MAX_SUGGESTIONS = 4;

class AutofillController extends Controller {
  static targets = ['source', 'datalist'];

  sourceTargets!: HTMLInputElement[];
  datalistTarget!: HTMLDataListElement;

  get autofillOptions(): string[] {
    const options = this.sourceTargets
      .map((target) => { return target.value.trim(); })
      .filter((value) => { return value !== ''; })
      .sort();

    return [...new Set(options)];
  }

  connect(): void {
    this.renderFilteredOptions('');
  }

  setOptions(event: DOMEvent<HTMLInputElement, HTMLInputElement>): void {
    this.renderFilteredOptions(event.target.value);
  }

  renderFilteredOptions(term: string): void {
    const filteredOptions = this.autofillOptions.filter((option) => {
      return option.toLowerCase().startsWith(term.toLowerCase()) && option !== term;
    }).slice(0, MAX_SUGGESTIONS);

    this.datalistTarget.innerHTML = '';

    filteredOptions.forEach((option) => {
      const element = document.createElement('option');
      element.setAttribute('value', option);
      this.datalistTarget.appendChild(element);
    });
  }
}

export default AutofillController;
