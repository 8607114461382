// @ts-strict-ignore
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import RailsForm from 'src/common/components/rails_form';
import { loadDialog } from 'src/helpers/dialog';
import Location from 'src/helpers/location';
import ActionableMessage from 'src/responses/components/table/actionable_message';
import AttachmentsIcon from 'src/responses/components/table/attachments_icon';
import Notes from 'src/responses/components/table/notes';

type Props = {
  attributes: string[];
  campaign: Campaign;
  response: FormResponse;
  step: Step;
};

const RECIPIENT_ATTRIBUTES = new Set(['formRecipients', 'waitingOn']);
const STATUS_TO_ACTIONABLE_MESSAGE_OPTS = {
  bounced: {
    linkText: 'View info',
    messageText: 'Email bounced',
    onClick: (props: Props): void => { _showBouncedEmailModal(props); },
    requiredPermission: 'change_approver',
  },
  'email missing': {
    linkText: 'Update',
    messageText: 'Email missing',
    onClick: (props: Props): void => {
      Location.navigateTo(props.response.subjectEditPath);
    },
    requiredPermission: 'edit_group',
  },
  'not sent': {
    linkText: 'Send now',
    messageText: 'Not delivered',
    requiredPermission: 'edit_group',
  },
};

class BodyRow extends React.Component<Props, never> {
  private waitingOnPopoverRef = React.createRef<HTMLButtonElement>();

  constructor(props: Props) {
    super(props);

    this.showChangeApproverModal = this.showChangeApproverModal.bind(this);
    this.showUpdateInitiatorModal = this.showUpdateInitiatorModal.bind(this);
  }

  render(): ReactNode {
    const { response } = this.props;

    return (
      <tr
        tabIndex={0}
        onClick={(): void => { Location.navigateTo(response.path); }}
        onKeyPress={(event): void => {
          if (event.key === 'Enter') { Location.navigateTo(response.path); }
        }}
      >
        {this.checkboxCell()}
        {this.dataCells()}
        {this.actionCell()}
      </tr>
    );
  }

  componentDidMount(): void {
    const waitingOnPopover = this.waitingOnPopoverRef.current;

    if (waitingOnPopover) { $(waitingOnPopover).popover(); }
  }

  checkboxCell(): React.JSX.Element {
    const { response } = this.props;

    return (
      <td
        className='checkboxes'
      >
        <label
          className='responses__checkbox-container'
          data-action='click->responses-selection#toggleOne:stop'
        >
          <input
            aria-label='Select Submission'
            className='responses__checkbox'
            data-record-id={response.formId}
            data-responses-selection-target='checkbox'
            readOnly
            type='checkbox'
          />
        </label>
      </td>
    );
  }

  actionCell(): React.JSX.Element {
    const { campaign, response } = this.props;
    const { userPermissions } = campaign;
    const formHistoryPath = `/forms/${response.formId}/events`;

    return (
      <td
        className='actions'
        onClick={(event): void => { event.stopPropagation(); }}
      >
        {this.attachmentsIcon()}
        {
          userPermissions.includes('view_note') &&
          this.hoverableNotes()
        }
        <span className='dropdown'>
          <button className='link-text' data-toggle='dropdown' type='button'>
            <span className='sr-only'>Actions</span>
            <Icon name='ellipsisVertical' />
          </button>
          <ul className='dropdown-menu dropdown-menu-right'>
            {_actionLink(response.path, 'Open')}
            {
              userPermissions.includes('edit_group') &&
              this.editRecipientAction()
            }
            {
              userPermissions.includes('view_form_history') &&
              _actionLink(formHistoryPath, 'Form history')
            }
            {
              userPermissions.includes('change_approver') &&
              this.changeApproverAction()
            }
            {
              userPermissions.includes('update_initiator') &&
              this.changeInitiatorAction()
            }
            { this.copyFormRequestAction() }
            {
              userPermissions.includes('cancel_form') &&
              this.cancelFormAction()
            }
          </ul>
        </span>
      </td>
    );
  }

  attachmentsIcon(): React.JSX.Element {
    const { response: { attachmentsCount } } = this.props;

    if (!attachmentsCount) { return null; }

    return <AttachmentsIcon count={attachmentsCount} />;
  }

  hoverableNotes(): React.JSX.Element {
    const { response: { notes } } = this.props;

    if (!notes) { return null; }

    return <Notes text={notes} />;
  }

  editRecipientAction(): React.JSX.Element | null {
    const { campaign, response } = this.props;

    if (!campaign.isActive || !response.subjectEditPath) {
      return null;
    }
    return _actionLink(response.subjectEditPath, 'Edit recipient');
  }

  changeApproverAction(): React.JSX.Element {
    const { campaign } = this.props;

    if (!campaign.isActive) { return null; }
    if (!this.isChangeApproverEnabled()) { return null; }

    return (
      <li>
        <button
          className='dropdown-menu__link'
          type='button'
          onClick={this.showChangeApproverModal}
        >
          Change approver
        </button>
      </li>
    );
  }

  isChangeApproverEnabled(): boolean {
    const { step, response } = this.props;
    const isCompleted = [
      'archived',
      'submitted',
      'marked complete',
      'denied',
    ].includes(response.status);

    if (!step || isCompleted) { return false; }

    return step.type === 'PromptStep' ||
      this.isChangeApproverEnabledForRecipientStep();
  }

  isChangeApproverEnabledForRecipientStep(): boolean {
    const { step, response } = this.props;

    if (step.type !== 'RecipientStep') { return false; }

    const hasMultipleRecipients = step.recipients.length > 1;
    const responseRecipientIsNotOnStep = step.recipients.length <= 1 &&
      step.recipients[0] !== response.recipientId;

    return hasMultipleRecipients || responseRecipientIsNotOnStep;
  }

  showChangeApproverModal(): void {
    const { response } = this.props;

    loadDialog(`/responses/${response.id}/recipient/edit`);
  }

  changeInitiatorAction(): React.JSX.Element {
    const { campaign } = this.props;

    if (!campaign.isLink || !campaign.isActive) {
      return null;
    }

    return (
      <li>
        <button
          className='dropdown-menu__link'
          type='button'
          onClick={this.showUpdateInitiatorModal}
        >
          Update initiator
        </button>
      </li>
    );
  }

  copyFormRequestAction(): React.JSX.Element | null {
    const { campaign, response } = this.props;

    if (!campaign.isLink || !campaign.isActive) {
      return null;
    }

    const copyFormPath =
      `${campaign.linkPath}&ie_fix_copy_from_form_request_id=${response.id}`;

    return _actionLink(copyFormPath, 'Copy to new form');
  }

  showUpdateInitiatorModal(): void {
    const { response } = this.props;

    loadDialog(`/responses/${response.id}/initiator/edit`);
  }

  cancelFormAction(): React.JSX.Element[] {
    const { campaign, response } = this.props;
    const url = `/forms/${response.formId}`;

    if (!response.cancellable || !campaign.isActive) { return null; }

    return [
      <li key='divider' className='divider'></li>,
      <li key='cancel'>
        <RailsForm action={url} method='PUT'>
          <input
            autoComplete='off'
            name='form[cancelled]'
            type='hidden'
            value='true'
          />
          <input
            autoComplete='off'
            name='campaign_id'
            type='hidden'
            value={campaign.id}
          />
          <button className='dropdown-menu__link' type='submit'>
            Cancel form
          </button>
        </RailsForm>
      </li>,
    ];
  }

  dataCells(): React.JSX.Element[] {
    const { attributes, response, campaign } = this.props;
    const responseStatus =
      response.recipientEmail ? response.status : 'email missing';
    const { isSentBack } = response;
    const attributeToDataMap = {
      lastActivity: response.lastActivity,
      status: this.statusMessage(response),
      waitingOn: this.waitingOnResponseData(response, isSentBack),
    };

    return attributes.map((attribute) => {
      let responseData = response[attribute];

      if (_isActionable(attribute, responseStatus, campaign.userPermissions)) {
        responseData = this.actionableMessage(responseStatus);
      } else if (Object.keys(attributeToDataMap).includes(attribute)) {
        responseData = attributeToDataMap[attribute];
      }

      return (
        <td key={attribute} className={kebabCase(attribute)}>
          {responseData || '--'}
        </td>
      );
    });
  }

  statusMessage(response): React.JSX.Element | string {
    const { campaign } = this.props;
    const status = _displayStatus(response.status);

    if (campaign.status === 'testing') {
      return (
        <React.Fragment>
          {status}
          <p className='warning-message wb-u-margin-t-1'>
            <Icon iconClassName='wb-u-margin-r-1' name='warning' />
            This submission will be deleted when the form is published
          </p>
        </React.Fragment>
      );
    }
    return status;
  }

  actionableMessage(responseStatus): React.JSX.Element {
    const { response } = this.props;
    const messageOpts = STATUS_TO_ACTIONABLE_MESSAGE_OPTS[responseStatus];

    let onClickParam = {};
    if (messageOpts.onClick) {
      onClickParam = { onClick: (): void => { messageOpts.onClick(this.props); } };
    }

    return (
      <ActionableMessage
        formId={response.formId}
        linkText={messageOpts.linkText}
        messageText={messageOpts.messageText}
        {...onClickParam}
      />
    );
  }

  waitingOnResponseData(
    response: FormResponse,
    isSentBack: boolean,
  ): React.JSX.Element {
    if (!response.waitingOn) { return null; }

    const { seq } = response;
    const displayName = displayRecipientTitle(response);
    const displayEmail = displayRecipientEmail(response);
    const popoverDataAttributes = {
      'data-content':
        _waitingOnPopoverText(displayName, displayEmail, isSentBack),
      'data-html': 'true',
      'data-placement': 'bottom',
      'data-toggle': 'popover',
      'data-trigger': 'hover | focus',
    };

    return (
      <button
        ref={this.waitingOnPopoverRef}
        className='link-text link-no-underline text-left'
        type='button'
        {...popoverDataAttributes}
      >
        {isSentBack && <Icon name='reply' />}
        {`Step ${seq}:`}
        <br />
        {displayName ? displayName : displayEmail}
      </button>
    );
  }
}

// private

function displayRecipientEmail(response): string {
  if (!response.recipientReceivingSubstitute) {
    return response.recipientEmail;
  }

  return `${response.recipientReceivingSubstitute.email}
     (Sub: ${response.recipientEmail})`;
}

function displayRecipientTitle(response): string {
  if (!response.recipientReceivingSubstitute) {
    return response.recipientName;
  }
  return response.recipientReceivingSubstitute.name;
}

function _displayStatus(status): string {
  let displayStatus = status;

  switch (status) {
    case 'submitted':
      displayStatus = 'completed';
      break;
    case 'routing':
      displayStatus = 'pending submittal';
      break;
    default:
      break;
  }

  return displayStatus;
}

function _actionLink(path, text): React.JSX.Element {
  return <li><a href={path}>{text}</a></li>;
}

function _isActionable(attribute: string, status, userPermissions): boolean {
  const messageOpts = STATUS_TO_ACTIONABLE_MESSAGE_OPTS[status];

  if (!RECIPIENT_ATTRIBUTES.has(attribute)) { return false; }
  if (!messageOpts) { return false; }
  return userPermissions.includes(messageOpts.requiredPermission);
}

function _waitingOnPopoverText(recipientName, recipientEmail, isSentBack): string {
  let title: string;
  let subtitle: string;

  if (recipientName) {
    title = `<strong>${recipientName}</strong><br />`;
    subtitle = `<span class='text-muted'>${recipientEmail}</span>`;
  } else {
    title = `<strong>${recipientEmail}</strong>`;
  }

  if (isSentBack) {
    title = `<h6 class='text-danger'>Sent back</h6> ${title}`;
  }

  return `${title}${subtitle || ''}`;
}

function _showBouncedEmailModal(props: Props): void {
  const { response } = props;

  loadDialog(`/responses/${response.id}/bounced_email/edit`);
}

export default BodyRow;
export type { Props };
