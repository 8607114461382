import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import PaginatorComponent from 'src/common/components/paginator';
import { assert } from 'src/helpers/assertion';
import { ApplySearchTerm } from 'src/responses/action_creators';

type Props = {
  campaign: Campaign;
  applySearchTerm: ApplySearchTerm;
  searchTerm: string | undefined;
  pagination: Pagination;
  formGroup: FormGroup;
  userFormGroupDescriptions: UserFormGroupDescriptions;
};

type State = {
  showClearIcon: boolean;
};

class ResponsesSearchPaginate extends React.Component<Props, State> {
  handleChangeDebounced: DebouncedFunc<Callback>;
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = { showClearIcon: Boolean(props.searchTerm) };

    this.handleChangeDebounced = debounce(() => {
      const input = assert(this.inputRef.current);
      const { applySearchTerm, campaign, searchTerm } = this.props;

      this.setState({ showClearIcon: input.value !== '' });
      if ((searchTerm || '').trim() !== input.value.trim()) {
        applySearchTerm(input.value.trim(), campaign);
      }
    }, 500);
  }

  componentWillUnmount(): void {
    this.handleChangeDebounced.cancel();
  }

  render(): ReactNode {
    const { showClearIcon } = this.state;
    const {
      searchTerm,
      pagination,
      formGroup,
      userFormGroupDescriptions,
    } = this.props;

    if (searchTerm) {
      const statusGroup = document.querySelector('.nav li.active');

      if (statusGroup) {
        statusGroup.classList.remove('active');
      }
    }
    return (
      <div
        className='responses-search'
        data-responses-selection-target='searchToolbar'
      >
        <div className=' responses-search__search'>
          <div className='input-group input-group-search'>
            <span className='input-group-addon responses-search__input-addon'>
              <Icon name='search' size='1-5x' />
            </span>
            <input
              ref={this.inputRef}
              className='form-control'
              data-action='keyup->responses-selection#toggleFiltersEnabled'
              data-responses-selection-target='searchInput'
              defaultValue={searchTerm}
              placeholder={`Search in ${userFormGroupDescriptions[formGroup]}`}
              type='search'
              onChange={this.handleChangeDebounced}
            />
            {showClearIcon && this.clearIcon()}
          </div>
        </div>
        <div className='hidden-sm hidden-xs responses-search__paginator'>
          <PaginatorComponent
            ariaLabel='Responses Search Pagination'
            compactDisplay
            pagination={pagination}
            paginatorClassNames='responses-pagination-top'
          />
        </div>
      </div>
    );
  }

  clearIcon(): React.JSX.Element {
    return (
      <span className='input-group-addon responses-search__input-addon'>
        <button
          aria-label='Clear'
          className='link-text link-no-underline'
          type='button'
          onClick={this.clearAndUpdate.bind(this)}
        >
          <Icon name='times' />
        </button>
      </span>
    );
  }

  clearAndUpdate(): void {
    const input = assert(this.inputRef.current);

    if (input) {
      input.value = '';
      this.setState({ showClearIcon: false });
      this.handleChangeDebounced();
    }
  }
}

export default ResponsesSearchPaginate;
export type { Props };
