// @ts-strict-ignore
import keyBy from 'lodash/keyBy';
import React from 'react';

import PillButton from 'src/common/components/pill_button';
import { isFilterApplied } from 'src/responses/helpers/query_params';

type Props = {
  campaign: Campaign;
  filters: object;
  headers: Responses.TableHeader[];
  searchTerm: string;
};

function ResponsesAppliedFilters(props: Props): React.JSX.Element {
  const { campaign, filters, headers, searchTerm } = props;
  const hasSearchTerm = Boolean(searchTerm);

  if (!isFilterApplied(filters) || hasSearchTerm) { return null; }

  const appliedFormDataFilters = {};
  const appliedFieldFilters = {};

  Object.keys(filters).forEach((filterKey) => {
    if (campaign.formDataFilters.hasOwnProperty(filterKey)) {
      appliedFormDataFilters[filterKey] = filters[filterKey];
    } else if (parseInt(filterKey, 10)) {
      appliedFieldFilters[filterKey] = filters[filterKey];
    }
  });

  return (
    <div
      className='responses-applied-filters'
      data-controller='responses-filter'
      data-responses-selection-target='appliedFiltersSection'
    >
      <div className='list-label'>Filters:</div>
      <ul className='list-inline'>
        {
          formDataFilterPills(
            campaign.formDataFilters,
            headers,
            appliedFormDataFilters,
          )
        }
        {
          fieldFilterPills(
            campaign.filterFields,
            appliedFieldFilters,
          )
        }
        <li key='clear'>
          <button
            className='btn btn-link'
            data-action='responses-filter#clearAllFilters'
            data-responses-selection-target='appliedFilter'
            type='button'
          >
            Clear All
          </button>
        </li>
      </ul>
    </div>
  );
}

// private

function formDataFilterPills(
  formDataFilters,
  headers,
  appliedFormDataFilters,
) {
  return Object.keys(appliedFormDataFilters).map((filterKey) => {
    const { dataType } = formDataFilters[filterKey];
    const filterLabel = keyBy(headers, 'attribute')[filterKey].title;

    const filterPillsProps = generatePillButtonProps(
      appliedFormDataFilters[filterKey],
      dataType,
      filterLabel,
      filterKey,
    );

    return pillButtons(filterPillsProps);
  });
}

function fieldFilterPills(
  filterFields,
  appliedFieldFilters,
) {
  return Object.keys(appliedFieldFilters).map((filterKey) => {
    const field = keyBy(filterFields, 'number')[filterKey];
    const { dataType } = field;
    const filterLabel = field.label;

    const filterPillsProps = generatePillButtonProps(
      appliedFieldFilters[filterKey],
      dataType,
      filterLabel,
      filterKey,
    );

    return pillButtons(filterPillsProps);
  });
}

function generatePillButtonProps(filter, dataType, filterLabel, filterKey) {
  switch (dataType) {
    case 'text': // text filters look like ['filter one', 'two', 'etc']
    case 'list':
    case 'number':
      return filter.map((textValue) => {
        return {
          filterKey,
          filterLabel,
          filterValue: textValue,
          key: `${filterKey}-${textValue}`,
        };
      });
    case 'date': // date filters look like {gte: 'YYYY-MM-DD', lte: 'YYYY-MM-DD'}
      return [{
        filterKey,
        filterLabel,
        filterValue: `${filter.gte} to ${filter.lte}`,
        key: filterKey,
      }];
    case 'boolean':
      return [{
        filterKey,
        filterLabel,
        filterValue: filter ? 'Yes' : 'No',
        key: filterKey,
      }];
    default:
      throw new Error(`received invalid dataType: ${dataType}`);
  }
}

function pillButtons(propsForPills): React.JSX.Element[] {
  return propsForPills.map((propsForPill) => {
    const { key, filterLabel, filterKey } = propsForPill;
    let { filterValue } = propsForPill;

    if (filterLabel === 'Status' && filterValue === 'Routing') {
      filterValue = 'Pending Submittal';
    }

    return (
      <li key={key}>
        <PillButton
          filterKey={filterKey}
          filterLabel={filterLabel}
          filterValue={filterValue}
        />
      </li>
    );
  });
}

export default ResponsesAppliedFilters;
export type { Props };
